import React, { useRef } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { animated, useSpring } from "react-spring";
import TopNavBar from "../components/TopNavBar";
import scrollArrow from "../images/icons/scroll-arrow.svg";
import Footer from "../components/Footer";
import casesHeaderImg from "../images/cases-header-img.jpg";
import americaTodayLogo from "../images/america-logo.png";
import msmodeLogo from "../images/msmode-logo.png";
import newblackLogo from "../images/newblack-logo.png";
import nedapLogo from "../images/nedap-logo.png";
import Helmet from "react-helmet";
import favicon from "../images/icons/favicon.png";

const Cases = () => {
  const breakpoints = useBreakpoint();

  const scrollBtnRef = useRef();

  const handleBackClick = () => {
    scrollBtnRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Animations
  const fadeInSlideUp = useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: 120 },
    delay: 800,
  });

  //Styles
  const CasesPageStyles = {
    color: "white",
    height: "100%",
    fontFamily: "League Spartan, Roboto, sans-serif, serif",
    zIndex: 1,
    padding: 0,
    margin: 0,
    letterSpacing: 0.66,
    //  overflow: "hidden",
    position: "relative",
    width: "100%",
  };

  const CasesHead = {
    backgroundImage: `url(${casesHeaderImg})`,
    backgroundSize: "cover",
    height: breakpoints.sm ? 650 : 725,
    backgroundRepeat: "repeat-y",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
    zIndex: -1,
  };

  const ScrollArrow = {
    position: "absolute",
    top: breakpoints.sm ? "60%" : "65%",
    left: "0",
    right: "0",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 100,
  };

  const CaseResults = {
    height: breakpoints.sm ? "100%" : 550,
    display: "flex",
    flexDirection: breakpoints.sm ? "column" : "row",
    justifyContent: "center",
    marginTop: -216,
    background:
      "linear-gradient(0deg, rgba(0,0,0,1) 65%, rgba(0,0,0,1) 75%, rgba(0,0,0,0) 100%)",
    padding: breakpoints.sm ? "246px 16px 16px 16px" : "284px 0 0 0",
  };

  const CasesResultsInner = {
    maxWidth: breakpoints.sm ? "100%" : 968,
    display: breakpoints.sm ? "flex" : "grid",
    flexDirection: "column",
    gridTemplate: "150px 1fr / 1fr 1fr 1fr",
    gridGap: breakpoints.sm ? 16 : 56,
  };

  const ResultsTitle = {
    width: breakpoints.sm ? "100%" : 500,
    gridRow: "1/2",
    gridColumn: "1/4",
    textAlign: "center",
    justifySelf: "center",
  };

  const Testimonials = {
    height: "100%",
    backgroundColor: "white",
    padding: breakpoints.sm ? "16px" : "32px 32px 108px 32px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#011413",
  };

  const TestimonialsInner = {
    display: "grid",
    gridTemplate: "auto / 1fr",
    justifySelf: "center",
    gridGap: breakpoints.sm ? 16 : 56,
  };

  const Testimonial = {
    justifySelf: "center",
    alignSelf: "center",
    width: breakpoints.sm ? "100%" : 860,
    display: "flex",
    flexDirection: breakpoints.sm ? "column" : "row",
    alignItems: "center",
    fontWeight: 600,
  };

  const CasesInfo = {
    position: "absolute",
    top: 300,
    right: 0,
    left: 0,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    height: 600,
    display: "flex",
    justifyContent: "center",
  };

  const HrStyle = {
    height: 1,
    border: "none",
    backgroundColor: "#DADADA",
    width: breakpoints.sm ? "100%" : 986,
  };

  const TestimonialImg = {
    width: 155,
    height: 95,
    marginRight: breakpoints.sm ? 0 : 56,
    justifySelf: "center",
    alignSelf: "center",
  };

  const PartnerImg = {
    width: "155px !important",
    height: 95,
    marginLeft: breakpoints.sm ? 0 : 76,
  };
  return (
    <div style={CasesPageStyles}>
        <Helmet>
          <title>Thunderstock</title>
          <link rel="canonical" href="http://thunderstock.nl" />
          <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        </Helmet>
      <TopNavBar />

      <div style={CasesHead}></div>

        <div style={CasesInfo}>
          <h2
            style={{
              width: breakpoints.sm ? "100%" : "100%",
              height: "100%",
              fontSize: 34,
              textAlign: "center",
            }}
          >
            Our success stories in retail
          </h2>

          <div style={ScrollArrow}>
            <animated.div style={fadeInSlideUp}>
              <img src={scrollArrow} onClick={handleBackClick} alt="arrow" />
            </animated.div>
          </div>
        </div>
        <div style={CaseResults} ref={scrollBtnRef}>
          <div style={CasesResultsInner}>
            <div style={ResultsTitle}>
              <h2 style={{ fontSize: 30 }}>Results</h2>
              <p>
                Our unique demand driven approach has shown great results among
                our customers within the first months of operation.
              </p>
            </div>

            <div style={{ textAlign: "center" }}>
              <h2 style={{ fontSize: 40 }}>20-30%</h2>
              <p style={{ color: "#FF9800" }}>decrease in required stock</p>
            </div>

            <div style={{ textAlign: "center" }}>
              <h2 style={{ fontSize: 40 }}>2-5%</h2>
              <p style={{ color: "#FF9800" }}>increase in realized margins</p>
            </div>

            <div style={{ textAlign: "center" }}>
              <h2 style={{ fontSize: 40 }}>10-20%</h2>
              <p style={{ color: "#FF9800" }}>increase in realized sales</p>
            </div>
          </div>
        </div>

        <div style={Testimonials}>
          <div style={TestimonialsInner}>
            <h2 style={{ fontSize: 30, justifySelf: "center" }}>Testimonials</h2>

            <div style={Testimonial}>
              <img
                src={americaTodayLogo}
                alt="america today logo"
                style={TestimonialImg}
                onClick={() => {
                  window.open("https://www.america-today.com", '_blank').focus();
                }}
              />
              <div>
                <h3 style={{ color: "#ff9800" }}>America Today</h3>
                <p>
                  “Thunderstock has revolutionized the way we think about stock,
                  we learned that being agile is paramount in optimizing stock
                  operations. The tool allowed us to reduce the stock levels
                  significantly, while also reducing missed sales. The agile stock
                  position paid off during the corona pandemic, where it allowed
                  us to quickly respond to change in demand patterns caused by
                  store closings.”
                </p>
              </div>
            </div>

            <div style={{ width: "100%", alignText: "center" }}>
              <hr style={HrStyle} />
            </div>

            <div style={Testimonial}>
              <img
                src={msmodeLogo}
                alt="msMode logo"
                style={TestimonialImg}
                onClick={() => {
                  window.open("https://www.msmode.nl/", '_blank').focus();
                }}
              />
              <div>
                <h3 style={{ color: "#ff9800" }}>MS Mode</h3>
                <p>
                  “We used to manage stock using advanced numerical optimization
                  tooling, while it was effective at dividing stock across our
                  channels, it did not look at customer demand and the idea of
                  “enough” stock. Over time stock levels kept growing, forcing us
                  to manually transfer goods weekly. Currently, Thunderstock
                  streamlines our allocation & replenishment, but also allows us
                  to approve proposed transfers easily.”
                </p>
              </div>
            </div>

            <hr style={{ ...HrStyle, width: "100%" }} />

            <h2 style={{ fontSize: 30, justifySelf: "center" }}>Partners</h2>

            <div style={Testimonial}>
              {breakpoints.sm ? (
                <img
                  src={newblackLogo}
                  alt="msMode logo"
                  style={PartnerImg}
                  onClick={() => {
                    window.open("https://www.newblack.io/", '_blank').focus();
                  }}
                />
              ) : (
                <></>
              )}
              <div>
                <h3 style={{ color: "#ff9800" }}>NewBlack</h3>
                <p>
                  Our partner New Black is a software company and the creator
                  behind EVA, an unified commerce platform. This revolutionary
                  proposition replaces complex IT landscapes and allows
                  interaction with consumers in new ways, on a global scale. With
                  their global coverage of POS systems and real time stock/order
                  insights, New Black perfectly fits with the Thunderstock
                  proposition. When combining our products, it will allow clients
                  to instantly make use of all Thunderstock modules.
                </p>
              </div>

              {breakpoints.sm ? (
                <></>
              ) : (
                <img
                  src={newblackLogo}
                  alt="New Black logo"
                  style={PartnerImg}
                  onClick={() => {
                    window.open('https://www.newblack.io/', '_blank').focus();
                  }}
                />
              )}
            </div>

            <hr style={HrStyle} />

            <div style={Testimonial}>
              {breakpoints.sm ? (
                <img
                  src={nedapLogo}
                  alt="Nedap logo"
                  style={PartnerImg}
                  onClick={() => {
                    window.open("https://www.nedap-retail.com/", '_blank').focus();
                  }}
                />
              ) : (
                <></>
              )}
              <div>
                <h3 style={{ color: "#ff9800" }}>Nedap</h3>
                <p>
                  Our partner Nedap is a software and hardware company, active in
                  different fields. Their philosophy of simplifying problems and
                  allowing focus for their users, fits perfectly with
                  Thunderstock. Their proposition of RFID is where our worlds
                  touch, it allows retail stores to get close to 100% stock
                  accuracy. We believe that this is crucial, when you want to
                  maximize profit out of the Thunderstock modules. Also our fully
                  integrated platforms allow for out of the box use cases, such as
                  Thunderstock transfer proposals in the Nedap iD Cloud
                  application.
                </p>
              </div>

              {breakpoints.sm ? (
                <></>
              ) : (
                <img
                  src={nedapLogo}
                  alt="msMode logo"
                  style={PartnerImg}
                  onClick={() => {
                    window.open("https://www.nedap-retail.com/", '_blank').focus();
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Footer />
      </div>
  );
};

export default Cases;
